export class DishSetSelectionVariationHandler {
  addGroupAttribute(groupAttribute) {
    let attributs = groupAttribute.attributs.filter((a) => {
      return this.productVariations.some((pv) => { return pv.attributeCollectionId.includes(a.id)})
    })
    let id = `basket_product_group_attribute_${this.productSelectionId}_${groupAttribute.id}`
    let name = `${this.baseName}[attributeCollectionId][${groupAttribute.group_type}[groupAttribute_${groupAttribute.id}]`
    $(this.element).append(`
      <div class='basket-form-slide' data-slide-name="${groupAttribute.name}" data-slide-type='groupAttribute' data-product-id="${this.productSelectionId}">
        <p class='basket-form-slide__title'>Choix de ${groupAttribute.name} pour ${$(this.element).data().name}</p>
        <input type="hidden" name="basket_product[attributeCollectionId][${groupAttribute.group_type}[groupAttribute_${groupAttribute.id}]" value="" autocomplete="off">
        <div class="basket-form-checkbox-container">
          ${this.addAttributes(attributs, groupAttribute)}
        </div>
      </div>
    `)
  }

  addAttributes(attributs, groupAttribute) {
    let html = ''
    $.each(attributs, (i, attribute) => {
      let checked
      if($(this.element).data().attributes.compositionAttributes.includes(attribute.id) || $(this.element).data().attributes.fabricationAttributes.includes(attribute.id)) {
        checked = true
      }
      let content = ''
      let picture = $('#form_slider').data().attributPictures[attribute.uniqueId]
      if(picture) {
        content = `<img src="${picture}" />`
      }
      content += `<p class='basket-form-checkbox__title'>${attribute.name}</p>`
      let name = `${this.baseName}[attributeCollectionId][${groupAttribute.group_type}][groupAttribute_${groupAttribute.id}]`
      html += `
        <div class="basket-form-checkbox-col">
          <label for="basket_product_attribute_${this.productSelectionId}_${attribute.id}" class="${checked ? 'has-checked-input' : ''}">
            <input class="form-check-input radio_buttons optional custom-control-input" type="radio" value="${attribute.id}"
              name="${name}"
              id="basket_product_attribute_${this.productSelectionId}_${attribute.id}"
              data-action="click->dishsetselection#attributeSelected" ${checked ? 'checked' : ''}
            >
            <div class='${picture ? 'has-picture' : ''}'>
              ${content}
            </div>
          </label>
        </div>
      `
    })
    return html
  }

  attributeSelected(attribute) {
    let selectedAttributs = $(this.element).find(':checked').map((i, e) => { return $(e).val()}).get()
    this.selectedProductVariations = this.productVariations.filter((pv) => { 
      return selectedAttributs.every((a) => {
        return pv.attributeCollectionId.includes(a)
      })
    })
    let break_loop = false
    $.each($(this.element).find('.basket_product_attribute').not($(attribute).parents('.basket_product_attribute')), (i, attribut_container) => {
      if(break_loop) {
        //return false
      }
      $.each($(attribut_container).find('.form-check-input'), (i, input) => {
        if(break_loop) {
          //return false
        }
        let isIncluded = this.selectedProductVariations.some((pv) => { return pv.attributeCollectionId.includes($(input).val()) })
        if($(attribut_container).find(':checked').length == 0) {
          $(input).prop('disabled', !isIncluded)       
        } else {
          if(!isIncluded && $(input).prop('checked')) {
            $(input).prop('checked', false)
            $(input).prop('disabled', true)
            break_loop = true
            this.attributeSelected()
          } else if(isIncluded) {
            $(input).prop('disabled', false)
          }
        }

      })
    })
    this.selectVariation()
    $('#form_slider').get(0).controller.attributeSelected()
  }

  selectVariation() {
    if(this.selectedProductVariations && this.selectedProductVariations.length == 1) {
      this.productVariationInput.val(this.selectedProductVariations[0].id)
      return this.selectedProductVariations[0].price
    } else {
      this.productVariationInput.val("00000000-0000-0000-0000-000000000000")
      return null
    }
  }

  handleVariations() {
    if($(this.element).data().pvs.length > 0) {
      this.productVariations = $(this.element).data().pvs  
      this.selectedProductVariations = this.productVariations
      this.groupAttributes = $(this.element).data().groupattribute.filter((e) => e.attributs.some((a) => {
        return this.productVariations.some((pv) => { return pv.attributeCollectionId.includes(a.id) })
      })).sort((a, b) => {
        if(this.groupAttributeOrders && !isNaN(this.groupAttributeOrders[a.id]) && !isNaN(this.groupAttributeOrders[b.id])) {
          return this.groupAttributeOrders[a.id] > this.groupAttributeOrders[b.id] ? 1 : -1
        } else {
          return 1
        }
      })

      $.each(this.groupAttributes, (i, groupAttribute) => { this.addGroupAttribute(groupAttribute)})
    } else {
      this.productVariationInput.val("00000000-0000-0000-0000-000000000000")
    }    
  }
}