import { MainController } from './main_controller';

export default class PaymentposController extends MainController {
  connect() {
    $.get('/checkout/create_order').done((data) => {
      if(data.persisted) {
        window.ticketData = data
        $('#payment-borne-success__back').show()
        this.printTicket(data)
        setTimeout(() => {
          if($('#payment-pos').length) {
            Turbolinks.visit('/shops/splash_screen')  
          }
        }, 20000)
        
      } else {
        Turbolinks.visit('/error_page')
      }

    })
  }

  printTicket(data) {
    let json = {
      shopId: data.shopId,
      id: data.uuid,
      dailyNumberOrder: data.number,
      basketPrice: data.price,
      products: data.items
    }
    try {
      app.printTicket(JSON.stringify(json));
    } catch (e) {
      console.log(e)
    }
  }  

  printDup() {
    let data = window.ticketData
    $('#payment-previous-step').remove()
    let json = {
      shopId: data.shopId,
      id: data.uuid,
      dailyNumberOrder: data.number,
      basketPrice: data.price,
      products: data.items
    }
    try {
      app.printTicket(JSON.stringify(json));
    } catch (e) {
      console.log(e)
      flashMessage('', `L'appel JS n'a pas fonctionné : ${e}`, 'success')
    }
  }  

  back() {
    Turbolinks.visit('/shops/splash_screen')
  }
}