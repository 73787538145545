import { Controller } from 'stimulus';
export default class ShopsplashController extends Controller {
  connect() {
    this.canClick = true
    this.remaining = 20
    this.element['controller'] = this
  }
  hideSplash() {
    if(!this.canClick) { return }
    this.canClick = false
    $.get('/healthcheck').done(data => {
      if(data && data.code == 200) {
        $.get('/shops/check_open_day').done(data => {
          if(data && data.code == 204) {
            if($(this.element).data().multipleDistributionType) {
              Turbolinks.visit('/shops/select_distribution_type_splash')
            } else {
              Turbolinks.visit('/')
            }   
          } else {
            this.showIssueDay()   
          }       
        })
      }
    })   
    
  }

  showIssueDay() {
    $('#shop-splash-screen-day-not-opened').show()
    this.interval = setInterval(() => {
      this.remaining -= 1
      let message = this.remaining.toString()
      if(this.remaining == 1) {
        message = message + " seconde"
      } else {
        message = message + " secondes"
      }
      $('#shop-splash-screen-day-not-opened__delay').text(message)
      if(this.remaining == 0) {
        clearInterval(this.interval)
        $('#shop-splash-screen-day-not-opened').hide()
        this.canClick = true
        this.remaining = 20
        $('#shop-splash-screen-day-not-opened__delay').text("20 secondes")
      }
    }, 1000)    
  }
}