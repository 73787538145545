import { MainController } from './main_controller';
export default class ModaldiscountController extends MainController {
  connect() {
    $(this.element).on('keyup', $(this.element).find('#modal-discountcode__input'), (e) => {
      $('#shop-show').get(0).controller.setTimer()
      if($('#modal-discountcode__input').val() && $('#modal-discountcode__input').val().length > 8) {
        //e.preventDefault()
        $('#modal-discountcode__input').val($('#modal-discountcode__input').val().slice(0, 8))
      }
      
    })
    $('#modal-discountcode__input').focus()
  }
  
  hideModal() {
    if($('#shop-show__scan-mode').length) {
      $('#modal_form').modal('hide')
    } else {
      $('#modal-discountcode').hide()
      $('.sub-modal-opened').removeClass('sub-modal-opened')
    }
    
  }

  send_discount_code() {
    $.ajax({
      type: 'POST',
      url: '/cart/discount_code',
      data: {code: $('#modal-discountcode__input').val()}
    }).done((data) => {
      if(data.result == 'not_found') {
        $('.modal-cart-container__content__miscs__discount-code__error').text("Ce code de réduction n'existe pas !")
      } else if (data.result == 'reload') {
        if(this.getControllerByIdentifier('cart')) {
          this.getControllerByIdentifier('cart').refresh()  
        }
        
        if($('#shop-show__scan-mode').length) {
          $('#modal_form').modal('hide')   
          $('#shop-show__scan-mode').get(0).controller.refresh()
        } else {
          $('#modal-cart-container').get(0).cartcontent.refresh()
          $('#modal-discountcode').hide()
          $('#modal_form').removeClass('sub-modal-opened')         
        }     

      } else if (data.result == 'already_used') {
        $('.modal-cart-container__content__miscs__discount-code__error').text("Votre code a déjà été utilisé !")
      } else {
        $('.modal-cart-container__content__miscs__discount-code__error').text(data.result)
      }
      if(data.result != 'reload') {
        $('.modal-cart-container__content__miscs__discount-code__error').show()
      }
    })
  }  
}