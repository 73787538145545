import { MainController } from './main_controller';
import { convertColor } from '../shared/psbc';
export default class PaymentbornesuccessController extends MainController {
  connect() {
    convertColor($(this.element).data().color)
    setTimeout(() => {
      if($('#payment-borne-success').length) {
        Turbolinks.visit('/shops/splash_screen')  
      }
      
    }, 20000)
  }

  back() {
    Turbolinks.visit('/shops/splash_screen')
  }
}