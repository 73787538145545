export function svgPlusCart() {
  return `
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M15.0005 6.25V23.75M6.25049 15H23.7505" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>        
  `
}

export function svgArrowRight() {
  return `
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M6.75 15H24.25M24.25 15L15.5 6.25M24.25 15L15.5 23.75" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  `
}

export function svgQuantityMinus() {
  return `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M4.51995 13.2469C4.1667 13.2469 3.87039 13.1272 3.63102 12.8879C3.39164 12.6485 3.27237 12.3526 3.2732 12.0002C3.2732 11.6469 3.39289 11.3506 3.63226 11.1112C3.87164 10.8719 4.16753 10.7526 4.51995 10.7534C13.4116 10.7534 9.80526 10.7534 19.481 10.7534C19.8342 10.7534 20.1305 10.8731 20.3699 11.1125C20.6093 11.3519 20.7286 11.6478 20.7277 12.0002C20.7277 12.3534 20.608 12.6497 20.3687 12.8891C20.1293 13.1285 19.8334 13.2478 19.481 13.2469C10.5845 13.2469 14.1815 13.2469 4.51995 13.2469Z" class='svg_minus_plus_fill' />
      </g>
    </svg>
  `
}

export function svgQuantityPlus() {
  return `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M12 20.7275C11.6467 20.7275 11.3504 20.6078 11.111 20.3684C10.8717 20.129 10.7524 19.8332 10.7532 19.4807V13.247H4.51946C4.16622 13.247 3.8699 13.1273 3.63053 12.8879C3.39115 12.6485 3.27188 12.3526 3.27271 12.0002C3.27271 11.647 3.3924 11.3507 3.63177 11.1113C3.87115 10.8719 4.16705 10.7526 4.51946 10.7535H10.7532V4.51971C10.7532 4.16646 10.8729 3.87015 11.1123 3.63077C11.3517 3.3914 11.6476 3.27212 12 3.27295C12.3532 3.27295 12.6495 3.39264 12.8889 3.63202C13.1283 3.87139 13.2476 4.16729 13.2467 4.51971V10.7535H19.4805C19.8337 10.7535 20.1301 10.8732 20.3694 11.1125C20.6088 11.3519 20.7281 11.6478 20.7272 12.0002C20.7272 12.3535 20.6076 12.6498 20.3682 12.8892C20.1288 13.1285 19.8329 13.2478 19.4805 13.247H13.2467V19.4807C13.2467 19.834 13.127 20.1303 12.8877 20.3697C12.6483 20.609 12.3524 20.7283 12 20.7275Z" class='svg_minus_plus_fill'/>
      </g>
    </svg>

  `
}