import { MainController } from './main_controller';
import { productHeader } from '../shared/product_header';
export default class AskmenuController extends MainController {
  connect() {
    $('#modal-ask-menus__card-price').text($(`#shop_product_${$(this.element).data().productId} .item_price`).text())
  }

  showProduct() {
    let target = $(`#shop_product_${$(this.element).data().productId}`)
    productHeader(target)
    $.get(`/basket_products/new?item_id=${target.data().itemId}`)    
  }

  showMenus() {
    if($('#modal-ask-menus__second-slide label').length == 1) {
      let target = $(`#${$('#modal-ask-menus__second-slide input').eq(0).data().id}`)
      productHeader(target)
      $.get(`/basket_menus/new?item_id=${target.data().itemId}`)
    } else {
      $('#modal-ask-menus__first-slide').hide()
      $('#modal-ask-menus__second-slide').show()
    }
    
  }
}