import { MainController } from './main_controller';
import { convertColor } from '../shared/psbc';
import { flashMessage } from '../shared/notify';
export default class PaymentborneController extends MainController {
  connect() {
    convertColor($(this.element).data().color)
    this.element['controller'] = this
    this.initializePayment()
  }

  initializePayment() {
    try {
      app.initializePayment(JSON.stringify({basketPrice: parseFloat($(this.element).data().basketPrice)}));
    } catch (e) {
      flashMessage('', `L'appel JS n'a pas fonctionné : ${e}`, 'success')
    }    
  }

  transactionAllow() {
    $.get('/checkout/create_order_before_borne_payment').done((data) => {
      if(data.code == 200) {
        this.order_uuid = data.order_uuid
        try {
          app.orderSendToProduction(JSON.stringify({order: data.order}));
        } catch(e) {
          console.log(e)
          flashMessage('', `L'appel JS n'a pas fonctionné : ${e}`, 'success')
        }
      } else {
        try {
          app.orderSendToProduction(JSON.stringify({order: {}, errorMessage: data.message}));
        } catch(e) {
          console.log(e)
          console.log({order: {}, errorMessage: data.message})
          flashMessage('', `L'appel JS n'a pas fonctionné : ${e}`, 'success')
        }
      }
    })
  }

  paymentFailed() {
    Turbolinks.visit('/')
  }

  paymentSucceeded() {
    $.ajax({
      method: 'POST',
      data: {order_uuid: this.order_uuid},
      url: '/checkout/confirm_order_borne_payment'
    }).done(() => {
      Turbolinks.visit('/checkout/payment_borne_success')
    })
  }
}