import { Controller } from 'stimulus';
import { MainController } from './main_controller';
import { flashMessage } from '../shared/notify';
import { productHeader } from '../shared/product_header';
import { numberToEuro } from '../shared/number_to_euro';

import PerfectScrollbar from 'perfect-scrollbar';
export default class CartcontentController extends MainController {

  connect() {
    this.element[this.identifier] = this
  }

  edit(e) {
    let data = $(e.currentTarget).parents('tr').data()
    let smartId = data.smartId
    let cartId = data.cartId
    let type = data.type
    $('.loading_app_container').removeClass('d-none')
    productHeader($(`#shop_${type}_${smartId}`).eq(0))
    $.get(`/basket_${type}s/${cartId}/edit`)

  }

  codeKeyup(e) {
    if($('#discount_code').val().length > 1 && e.which == 13) {
      this.send_discount_code()
    }
  }

  remove(e) {
    $('.loading_app_container').removeClass('d-none')
    let data = $(e.currentTarget).parents('tr').data()
    let element = $(e.currentTarget).parents('tr')
    $.ajax({
      url: `/cart/${data.cartId}?type=${data.type}`,
      type: 'DELETE',
      success: (data) => {
        if(data.code == 200) {
          element.remove()
          $('#modal_form .bornes-modal-cart-subtitle').text(`(${$('.modal-cart-container__content__items tr').length} produits)`)
          this.getControllerByIdentifier('cart').refresh()
          // this.refresh()
          if($('.modal-cart-container__content__items tr').length == 0) {
            $('#modal_form').modal('hide')
            $('.loading_app_container').addClass('d-none')
          } else {
            $.get('/cart/get_total').done(data => {
              $('.bornes-modal-cart__item-total span').eq(1).text(numberToEuro(data.total))
              $('.loading_app_container').addClass('d-none')
            }) 
          }
        }
      }
    })
  }

  toggleDiscountInput() {
    $('.modal-cart-container__content__miscs__discount-code__add-code').hide()
    $('.modal-cart-container__content__miscs__discount-code__input-container').show()
    $('.modal-cart-container__content__miscs__discount-code__input-container input').focus()
  }

  send_discount_code() {
    $.ajax({
      type: 'POST',
      url: '/cart/discount_code',
      data: {code: $('#discount_code').val()}
    }).done((data) => {
      if(data.result == 'not_found') {
        $('.modal-cart-container__content__miscs__discount-code__error').text("Ce code de réduction n'existe pas !")
      } else if (data.result == 'reload') {
        this.refresh()
      } else if (data.result == 'already_used') {
        $('.modal-cart-container__content__miscs__discount-code__error').text("Votre code a déjà été utilisé !")
      } else {
        $('.modal-cart-container__content__miscs__discount-code__error').text(data.result)
      }
      if(data.result != 'reload') {
        $('.modal-cart-container__content__miscs__discount-code__error').show()
      }
    })
  }

  remove_discount() {
    $.get('/cart/remove_discount', (data) => {
      this.refresh()
      this.getControllerByIdentifier('cart').refresh()
    })
  }

  printTicket(data) {
    let json = {
      shopId: data.shopId,
      id: data.uuid,
      dailyNumberOrder: data.number,
      basketPrice: data.price,
      products: data.items
    }
    try {
      app.printTicket(JSON.stringify(json));
    } catch (e) {
      console.log(e)
      flashMessage('', `L'appel JS n'a pas fonctionné : ${e}`, 'success')
    }
  }  

  openDiscount() {
    $('#modal-discountcode').show()
    $('#modal-discountcode').html('')
    $.get('/cart/open_discount')
    $('#modal_form').addClass('sub-modal-opened')
  }

  send_cart() {
    let paymentMode = $('#shop-show').data().paymentMode
    if(paymentMode == 'both') {
      Turbolinks.visit('checkout/choose_payment_mean')
    } else if(paymentMode == 'pos') {
      Turbolinks.visit('checkout/payment_pos')
    } else {
      Turbolinks.visit('checkout/payment_borne')
    }
  }

  refresh() {
    $.get('/cart/reload')
  }
}
