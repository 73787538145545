import { MainController } from './main_controller';
import { flashMessage } from '../shared/notify';
export default class ShowandroidpopupController extends MainController {
  clickButton(e) {
    let json = {
      button: parseInt($(e.currentTarget).data().index),
      id: $(this.element).data().id,
      action: $(this.element).data().action,
      type: $(this.element).data().type
    }
    try {
      app.answerAndroidPopup(JSON.stringify(json));
    } catch (e) {
      console.log(e)
      console.log(json)
      flashMessage('', `L'appel JS n'a pas fonctionné : ${e}. Body: ${json}`, 'success')
    }
    $('#generic-modal-android').modal('hide')
  }
}