import { MainController } from './main_controller';

import { ProductVariationHandler } from '../classes/product_variation_handler';
import { MixAttributeHandler } from '../classes/mix_attribute_handler';
import { SupplementSelectionHandler } from '../classes/supplement_selection_handler';
import { SupplementSelectionBuilder } from '../classes/supplement_selection_builder';

import { GenericNav } from '../classes/generic_nav';
import { BasketProductNav } from '../classes/basket_product_nav';
import { BasketProductQuantityPrice } from '../classes/basket_product_quantity_price';

import { classAgregator } from '../shared/class_agregator';
import { flashMessage } from '../shared/notify';
import { numberToEuro } from '../shared/number_to_euro';

export default class BasketproductController extends classAgregator(MainController, GenericNav, BasketProductNav, BasketProductQuantityPrice) {
  static targets = ['slide']

  connect() {
    this.element['controller'] = this
    this.currentSlide = 0
    this.quantityInput = $('#basket_product_quantity')
    this.maxSlide = 0
    this.ppuLength = 1
    this.productVariationHandler = new ProductVariationHandler($('#basket_product_productVariationId'))
    this.groupSupplements = $(this.element).data().groupSupplement.sort((a, b) => {
      let a_order = $(this.element).data().localGroupSupplements[a.id] ? parseInt($(this.element).data().localGroupSupplements[a.id].order) : 1000
      let b_order = $(this.element).data().localGroupSupplements[b.id] ? parseInt($(this.element).data().localGroupSupplements[b.id].order) : 1000

      return a_order - b_order
    })
    this.mixAttributeHandler = new MixAttributeHandler
    this.supplementSelectionHandler = new SupplementSelectionHandler
    setTimeout(() => {
      if($('#basket_product_productPriceUnitId .basket-form-checkbox-col input').length == 1) {
        this.ppuLength = 0
        $('#basket_product_productPriceUnitId .basket-form-checkbox-col input').eq(0).prop('checked', true) 
        $('#basket-form-slide__ppu').removeClass('basket-form-slide')
      }
      if($('#basket_product_productPriceUnitId :checked').length) {
        this.productPriceUnitChange()
      }

      // this.reloadMixable()
      this.reloadNav()
      this.selectSlide()
      
    }, 200)    
  }

  productPriceUnitChange() {
    let unitId = $('#basket_product_productPriceUnitId :checked').data().unitId
    $('#basket_product_unitId').val(unitId)
    $('#basket-form-slide__quantity').toggleClass(
      'basket-form-slide',
      $(this.element).data().minimal || $('#basket_product_productPriceUnitId :checked').data().unit == "Kilogramme(s)"
    )
    $('.basket-form-quantity-container__symbol').text($('#basket_product_productPriceUnitId :checked').data().unit)
    this.productVariationHandler.populate(unitId, $(this.element), $('.product_variations_container'), $(this.element).data().basketProduct)
    this.handleMixables()

    this.supplementSelectionHandler.checkQuantities()
    this.reloadNav() 
  }

  productPriceUnitClicked() {
    $('#basket-form-slide__ppu').data('valid', 'true')
    this.productPriceUnitChange()
    if($('.basket-form-nav__button').length > 1) {
      this.maxSlide = 1
      this.currentSlide = 1
      this.reloadNav()
    }
    this.selectSlide()
  }

  handleMixables() {
    $('.mix_attributes_container').html('')
    this.addedGroupSupplements = []
    let result = this.mixAttributeHandler.populate(
      $(this.element).data().mixAttributes[$('#basket_product_productPriceUnitId :checked').data().unitId],
      $('#basket_product_productPriceUnitId :checked').data().unit.toLowerCase().replace('(s)', ''),
      $(this.element),
      $('.mix_attributes_container'),
      this.groupSupplements,
      undefined,
      'basket_product'
    )
    this.mixableMode = result.mode
    this.addedGroupSupplements = result.groupSupplementIds
    $('#basket-form-slide--supplements').html('')
    $('#basket-form-slide--supplements').removeClass('basket-form-slide')
    let groupSupplements = this.groupSupplements.filter((group) => {return !this.addedGroupSupplements.includes(group.id)})
    if(groupSupplements.length) {
      $.each(groupSupplements, (i, groupSupplement) => {
        $('#basket-form-slide--supplements').append(
          new SupplementSelectionBuilder(groupSupplement, $(this.element).data(), false, 'basket_product').build()
        )
      })
      $('#basket-form-slide--supplements').addClass('basket-form-slide')
    }
  }

  reloadMixables() {
    let attrs = []
    $.each($('.mixable_input'), (index, input) => {
      for (let i = 0; i < parseInt($(input).val()); i++) {
        attrs.push($(input).data().mixable)
      }
    })

    $('#basket_product_mixableAttributes').val(attrs.join('_'))
    if(this.mixableMode == 'quantity') {
      $('#basket_product_quantity').val(attrs.length)
    }    
  }

  getMixableAttribute(uuid) {
    return this.mixAttributeHandler.getMixableAttribute(uuid)
  }

  showRemovable() {
    $('#basket_item_footer__removable').hide()
    $('.basket-form-slide.active').removeClass('active')
    $('#basket-form-slide__removable-ingredient').addClass('basket-form-slide active')
    $('#basket-form-nav').html('')
  }

  attributeSelected(e) {
    this.productVariationHandler.attributeSelected($(e.currentTarget))
    let slide = $(e.currentTarget).parents('.basket-form-slide').eq(0)
    $('.basket-form-slide').eq(this.currentSlide).data('valid', 'true')
    this.currentSlide = slide.index('.basket-form-slide') + 1
    if(this.currentSlide == $('.basket-form-slide').length) {
      this.currentSlide = $('.basket-form-slide').length - 1
    }
    if(this.maxSlide < this.currentSlide) {
      this.maxSlide = this.currentSlide
    }
    // this.reloadMixable()
    this.selectSlide()
    this.reloadNav()
  }  

  supplementChanged(e) {
    this.supplementSelectionHandler.quantityChanged($(e.currentTarget), $(e.currentTarget).data().factor == 'minus' ? -1 : 1)
    this.selectSlide()
  } 

  submit() {
    let type = $('#form_slider').hasClass('new_basket_product') ? 'POST' : 'PUT'
    $('.basket_product_mix_quantity input').prop('disabled', false)
    
    if(this.sent) {
      return
    }
    this.sent = true
    $('.loading_app_container').removeClass('d-none')
    $.ajax({
      type: type,
      url: $('#form_slider').attr('action'),
      data: $('#form_slider').serialize(),
      success: (data) => {
        $('.loading_app_container').addClass('d-none')
        if(data.code != 200) {
          $('.modal').modal('hide')
          flashMessage('', "Votre produit a été supprimé du panier", 'warning')
          this.refreshCart()
          return
        }
        if(data.out_of_stock) {
          $('.form-slider').html(`
            <div class='p-4'>
              <p>${data.out_of_stock}</p>
              <div class="d-flex justify-content-end">
                <p class="mb-0 btn btn-primary" data-dismiss="modal">
                  Continuer mes achats
                </p>
              </div>
            </div>
          `)
        } else if(data.suggestions) {
          $.get(`/shops/suggestions?type=SmartCollectProduct&id=${data.smartCollectProductId}`)
          this.refreshCart()
        } else {
          $('.modal').modal('hide')
          this.refreshCart()
        }
      }
    })
  } 

  refreshCart() {
    if($('#shop-show__scan-mode').length) {
      $('#shop-show__scan-mode').get(0).controller.refresh()
    } else {
      this.getControllerByIdentifier('cart').refresh()
    }
  }
}