import { Controller } from 'stimulus';
export default class SigninborneController extends Controller {

  connect() {
    this.shopname = 'olousouzian-test-484'
  }

  shopnameChanged(e) {
    this.shopname = $(e.currentTarget).val()
    $(e.currentTarget).toggleClass('is-valid', $(e.currentTarget).val().length > 0)
    $(e.currentTarget).toggleClass('is-invalid', $(e.currentTarget).val().length == 0)
  }

  passwordChanged(e) {
    this.password = $(e.currentTarget).val()
    $(e.currentTarget).toggleClass('is-valid', $(e.currentTarget).val().length > 0)
    $(e.currentTarget).toggleClass('is-invalid', $(e.currentTarget).val().length == 0)    
  }

  submit() {
    if(this.shopname.length && this.password.length) {

      const data = { 
        username: this.shopname,
        password: this.password,
      }; 
      console.log(data)
       
      let xhr = new XMLHttpRequest(); 
      xhr.open('POST', `${$(this.element).data().url}auth`); 
      xhr.setRequestHeader('Content-Type', 'application/json'); 
      xhr.onreadystatechange = function()
      {
        if (this.readyState == 4 && this.status == 200) {
          var responseJsonObj = JSON.parse(this.responseText);
          $('#bornes-shop-signin__error').hide()
          $.ajax({
            method: 'POST',
            url: '/shops/init_shop',
            data: {shopId: responseJsonObj.shopId}
          }).done((data) => {
              try {
                app.shopInit(JSON.stringify({shopName: data.name, shopId: responseJsonObj.shopId}));
              } catch (e) {
                console.log(e)
              }    
              Turbolinks.visit('/shops/splash_screen')
          })
        } else {
          $('#bornes-shop-signin__error').show()
        }
      };      
      xhr.send(JSON.stringify(data));    
    }
  }
}