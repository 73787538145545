import { MainController } from './main_controller';

export default class BasketproductheaderController extends MainController {
  connect() {
    this.element['controller'] = this
  }

  clickNav(e) {
    $('#form_slider').get(0).controller.clickNav(e)
  }

  initForm() {
    $('.basket_product_modal_description_show_more').toggle(
      $('.basket_product_modal_description').get(0).scrollHeight > $('.basket_product_modal_description').get(0).offsetHeight
    )
  }

  showMore() {
    $('.basket_product_modal_description_show_more').hide()
    $('.basket_product_modal_description').css('overflow', 'visible')
    $('.basket_product_modal_description').css('maxHeight', 'none') 
  }
}