import { Controller } from 'stimulus';
var moment = require('moment');
export default class CartController extends Controller {

  connect() {
    this.element[this.identifier] = this
    this.refresh()
  }

  refresh() {
    $.get('/cart/load')
  }
}
