import { Controller } from "stimulus"
import { MainController } from './main_controller';

export default class BasketproductfooterController extends MainController {
  connect() {
    this.element['controller'] = this
  }

  prevSlide() {
    $('#form_slider').get(0).controller.prevSlide()
  }

  showRemovable() {
    $('#form_slider').get(0).controller.showRemovable()
  }

  nextSlide() {
    $('#form_slider').get(0).controller.nextSlide()
  }

  submit() {
    $('#form_slider').get(0).controller.submit()
  }
}