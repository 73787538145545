// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

import 'controllers';
require('home/navbar')
require('shared/modal')
require('shared/notify')
require('shared/init')
require('home/shop_show')
// require('libs/scroll_center')
require('bootstrap')
import { flashMessage } from '../shared/notify';
import { stickyNav } from 'shared/sticky_nav'

window.jQuery = $;
window.$ = $;

// changes the default 500ms threshold to show progress bar
Turbolinks.BrowserAdapter.prototype.showProgressBarAfterDelay = function() {
  return this.progressBarTimeout = setTimeout(this.showProgressBar, 50);
};
$(document).ajaxError(function myErrorHandler(event, xhr, ajaxOptions, thrownError) {
  $('.loading_app_container').addClass('d-none')
  flashMessage('', `La borne n'est pas connectée à internet.`, 'success')
});

// KeepCool contact : handleScannedBarcode('0442A7E22C6180')
window.handleScannedBarcode = function(barcode) {
  //$('#shop-show__scan-mode').css('backgroundColor', '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'))
  if($('.full-height-shop').length) {
    return false
  }
  //flashMessage('', `Le code ${barcode} a été reçu`, 'success')
  $.ajax({
    method: 'POST',
    url: '/scan_handle',
    data: {barcode: barcode}
  }).done(data => {
    if($('#shop-splash-screen').length) {
      $.get('/healthcheck').done(data => {
        if(data && data.code == 200) {
          $.get('/shops/check_open_day').done(data => {
            if(data && data.code == 204) {
              $('#shop-splash-screen-day-not-opened').hide()
              if($('#shop-splash-screen').data().multipleDistributionType) {
                Turbolinks.visit('/shops/select_distribution_type_splash')
              } else {
                Turbolinks.visit('/')
              }   
            } else {
              $('#shop-splash-screen').get(0).controller.showIssueDay()
            }       
          })
        }
      })   
    } else {
      if(data.status == 200) {
        $('#shop-show').get(0).controller.resetCountDown()
        $('#shop-show__scan-mode').get(0).controller.refresh()
        if(data.scanned_object != 'product') {
          $('#shop-show__scan-mode').get(0).controller.refreshContact()
        }
        
      } else {
        flashMessage('', `Aucun produit ne correspond au code barre scanné`, 'success')
      }
    }
  })
}

window.transactionAllow = function() {
  if($('#payment-borne__container').length) {
    $('#payment-borne__container').get(0).controller.transactionAllow()
  }
}

window.paymentSucceeded = function() {
  if($('#payment-borne__container').length) {
    $('#payment-borne__container').get(0).controller.paymentSucceeded()
  }
}

window.paymentFailed = function() {
  if($('#payment-borne__container').length) {
    $('#payment-borne__container').get(0).controller.paymentFailed()
  }
}

window.closeOrder = function() {
  $.get('/cart/empty_cart').done(() => {
    Turbolinks.visit('/shops/splash_screen')
  })
}

window.showPaymentType = function() {
  $.get('/checkout/redirect_payment_mean').done((data) => {
    Turbolinks.visit(data.url)
  })
}

// showAndroidPopup({button: ['Annuler', 'Confirmer'], title: 'My title', description: 'My description', id: '96df8dd6-c811-4f74-a661-bc7b5de29181', action: 'key_action'})
// showAndroidPopup({button: ['Annuler', 'Oui', 'Confirmer'], title: 'My title', description: 'My description', id: '96df8dd6-c811-4f74-a661-bc7b5de29181', action: 'key_action'})
window.showAndroidPopup = function(params) {
  let buttons = ""
  $.each(params.button, (i, text) => {
    buttons += `
      <p class='generic-modal-android__button' data-index='${i}' data-action="click->showandroidpopup#clickButton">${text}</p>
    `
  })
  let body = `
    <div id="generic-modal-android__body" data-controller='showandroidpopup' data-id="${params.id}" data-action="${params.action}" data-type="${params.type}">
      <p id="generic-modal-android__title">${params.title}</p>
      <p id="generic-modal-android__description">${params.description}</p>
      <div id="generic-modal-android__actions" class="generic-modal-android__actions--${params.button.length > 2 ? 'vertical' : 'horizontal'}">
        ${buttons}
      </div>
    </div>
  `

  if(params.forceAnswer) {
    $('#generic-modal-android').modal({
      show: true,
      backdrop: 'static',
      keyboard: false
    })
  } else {
    $('#generic-modal-android').modal('show')
  }
  
  $('#generic-modal-android .modal-content').html(body)
  $('.loading_app_container').addClass('d-none')

}




$(document).on('turbolinks:load', function() {
  $('body').removeClass('loading')
  if($('#shops_index').length) {
    $('.cropped_card img').height($('.cropped_card img').eq(0).innerWidth() * .25)
  }
})

$(document).on('hide.bs.modal', '.modal', () => {
  $('#basket-form__product-description').hide()
  $('#modal-discountcode').hide()
  $('#modal_form').removeClass('sub-modal-opened')
})

$(document).on('turbolinks:request-start', function(e) {
  //console.log(e)
  if($('#shop-splash-screen').length) {
    $('#shop-splash-screen-transition').show()
  }
})

$(document).on('turbolinks:render', function(e) {
  console.log(e)
})

$(document).on('change', '.attribut_fieldset input.check_boxes', function() {
  $(this).parents('fieldset.check_boxes').find('input').not(this).prop('checked', false)
})

$(document).on('change', ".basket-form-checkbox-container input[type='radio']", (e) => {
  $(e.currentTarget).parents('.basket-form-checkbox-container').find('.has-checked-input').removeClass('has-checked-input')
  if($(e.currentTarget).prop('checked')) {
    $(e.currentTarget).parent().addClass('has-checked-input')
  }
})

$(document).on('change', ".basket-form-checkbox-container input[type='checkbox']", (e) => {
  if($(e.currentTarget).parents('.basket-form-slide').length && $(e.currentTarget).parents('.basket-form-slide').eq(0).data().slideType == 'mixAttribute') {
    $(e.currentTarget).prop('checked', !$(e.currentTarget).prop('checked'))
    $(e.currentTarget).parents('.basket-form-slide').get(0).controller.toggleCheckbox($(e.currentTarget))
  } else {
    $(e.currentTarget).parent().toggleClass('has-checked-input', $(e.currentTarget).prop('checked'))
  }
  
})



$(document).on('click', '.select_delivery_params', (e) => {
  $.get('/cart/edit_delivery')
/*  deliveryModal(e)
  $('.card_delivery_at_container').siblings().css('opacity', '.5')
  $('#modal_calendar_icon').css('opacity', '1')
  $('#modal_icon_cart_logo').css('opacity', '.5')*/
})

$(document).on("turbolinks:before-cache", function() {
  $('body').removeClass('loading')
})

$(document).on('click', 'body', function(e) {
  var container = $("#cart_content");
  // if the target of the click isn't the container nor a descendant of the container
  if (!container.is(e.target) && container.has(e.target).length === 0 && $(e.target).get(0).id != 'icon_cart')
  {
    container.hide();
  }
})
