import { MainController } from './main_controller';

export default class ShopselectdistributiontypeController extends MainController {
  click(e) {
    let element
    console.log($(e.target))

    if($(e.target).parents('.shop-splash-screen__distribution-type-card').length) {
      element = $(e.target).parents('.shop-splash-screen__distribution-type-card').eq(0)
    } else if($(e.target).hasClass('shop-splash-screen__distribution-type-card')) {
      element = $(e.target)
    }

    if(element) {
      $('.shop-header__distribution-type.active').removeClass('active')
      $(`#shop-header__distribution-type__${element.data().distributionType}`).addClass('active')
      $.ajax({
        method: 'POST',
        url: '/shops/upsert_distribution_type',
        data: {distribution_type: element.data().distributionType}
      }).done(() => {
        this.hideModal()
      })      
    } else {
      this.hideModal()
    }
  }

  hideModal() {
    $('.modal-backdrop').remove()
    $('#modal-distribution-type').hide()
  }
}