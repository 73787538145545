import { numberToEuro } from '../shared/number_to_euro';
import { svgPlusCart, svgArrowRight } from '../shared/svg_icons';

export class BasketProductNav {
  reloadNav() {
    $('#basket-form-nav__titles').html('')
    if($('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__maintitle').length == 1) {
      $('#basket-form-nav__titles').append(`
        <p class='basket-form-slide__title'>${$('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__maintitle').eq(0).text()}</p>
      `)
      if($('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__mainsubtitle').length == 1) {
        $('#basket-form-nav__titles').append(`
          <p class='basket-form-slide__subtitle'>${$('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__mainsubtitle').eq(0).text()}</p>
        `)
      }
    }

    if($('.basket-form-slide').length == 1) {
      return
    }

    let index = 0
    let buttons = ""

    $.each($('.basket-form-slide'), (i, slide) => {
      if($(slide).data().slideType == 'ppu' && $('#basket_product_productPriceUnitId label').length > 1) {
        index += 1
        buttons += this.addSlideButton(1, "Unité")
      } else if($(slide).data().slideType != 'ppu') {
        index += 1
        buttons += this.addSlideButton(index, $(slide).data().slideName)
      }
    })

    $('#basket-form-nav').html(buttons)
    
    $('.basket-form-nav__button').removeClass('active')
    $('.basket-form-nav__button').eq(this.currentSlide).addClass('active')
    $.each($('.basket-form-nav__button'), (i, e) => {
      $(e).toggleClass('forbidden', i > this.maxSlide)
    })

  }

  addSlideButton(index, name) {
    return `
      <div class='basket-form-nav__button' data-action="click->basketproductheader#clickNav">
        <div class='basket-form-nav__button__square'>${index}</div>
        <div class='basket-form-nav__button__text'>${name}</div>
      </div>
    `
  }

  prevSlide() {
    if($('#basket-form-slide__removable-ingredient.active').length) {
      $('#basket-form-slide__removable-ingredient').removeClass('basket-form-slide active')
      $('#basket_item_footer__removable').show()
      $('.basket-form-slide:last').addClass('active')
      this.reloadNav()

    } else if (this.currentSlide == 0) {
      $('#modal_form').modal('hide')
    } else {
      this.currentSlide -= 1
      this.reloadNav()
      this.selectSlide()
    }
  }

  setFooter() {
    $('.basket_item_price').text(numberToEuro(this.calcTotal()))
    let valid = $('.basket-form-slide').eq(this.currentSlide).length && $('.basket-form-slide').eq(this.currentSlide).data().valid && ([true, 'true'].includes($('.basket-form-slide').eq(this.currentSlide).data().valid))
    if(this.currentSlide == ($('.basket-form-slide').length - 1) && valid) {
      let html = ''
      if($('#basket-form-slide__removable-ingredient').length) {
        html += `
          <div id="basket_item_footer__removable">
            <p data-action="click->basketproductfooter#showRemovable">Personnaliser les ingrédients</p>
          </div>
        `
      }

      html += `
        <div id="basket_item_footer__main">
          <p class='basket_item_footer__return-button' data-action='click->basketproductfooter#prevSlide'>Retour</p>
          <div>
            <p class='basket_item_footer_submit-button' data-action='click->basketproductfooter#submit'>
              <span>Ajouter au panier (${numberToEuro(this.calcTotal())})</span>
              ${svgPlusCart()}           
            </p>
          </div>
        </div>
      `
      $('#basket_item_footer').html(html)
      $('#basket_item_footer').removeClass('return-only') 
    } else if($('.basket-form-slide').eq(this.currentSlide).data().next && valid) {
      $('#basket_item_footer').html(`
        <div id="basket_item_footer__main">
          <p class='basket_item_footer__return-button' data-action='click->basketproductfooter#prevSlide'>Retour</p>
          <div>
            <p class='basket_item_footer_submit-button' data-action='click->basketproductfooter#nextSlide'>
              <span>Suivant</span>
              ${svgArrowRight()}             
            </p>
          </div>
        </div>
      `)
      $('#basket_item_footer').removeClass('return-only') 
    } else {
      $('#basket_item_footer').html(`
        <div id="basket_item_footer__main">
          <p class='basket_item_footer__return-button' data-action='click->basketproductfooter#prevSlide'>Retour</p>
        </div>
      `)
      $('#basket_item_footer').addClass('return-only')     
    }
  }
}