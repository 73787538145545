import {v4 as uuidv4} from 'uuid';

import { pluralize } from '../shared/pluralize';
import { numberToEuro } from '../shared/number_to_euro';
import { svgQuantityMinus, svgQuantityPlus } from '../shared/svg_icons';

import { SupplementSelectionBuilder } from '../classes/supplement_selection_builder';

export class MixAttributeHandler {
  populate(mixables, unitName, container, destination, groupSupplements, productId, baseName) {
    this.container = container
    this.destination = destination
    this.basketProduct = container.data().basketProduct
    this.unitName = unitName
    this.mixAttributes = {}
    this.groupSupplements = groupSupplements
    this.groupSupplementIds = []
    this.productId = productId
    this.baseName = baseName

    $.each(mixables, (i, mixAttribute) => {
      this.addMixAttribute(mixAttribute)
    })
    let overrides = Object.values(this.mixAttributes).map((obj, index) => {return obj.isOverrideQuantity})
    return {
      mode:  (overrides.length == 1 && !overrides[0]) ? 'quantity' : 'overriden',
      groupSupplementIds: this.groupSupplementIds
    }
  }

  getMixableAttribute(uuid) {
    return this.mixAttributes[uuid]
  }

  addMixAttribute(mixAttribute) {
    let uuid = uuidv4();
    this.mixAttributes[uuid] = mixAttribute
    let supplements = ''
    if(mixAttribute.groupSupplementId
      && this.container.data().supplement
      && this.container.data().supplement[mixAttribute.groupSupplementId]
      && !this.groupSupplementIds.includes(mixAttribute.groupSupplementId)
    ) {
      let groupSupplement = this.groupSupplements.filter((group) => {return group.id == mixAttribute.groupSupplementId})[0]
      this.groupSupplementIds.push(mixAttribute.groupSupplementId)
      supplements = `
        <div class='basket-form-supplement-selections'>
          ${new SupplementSelectionBuilder(groupSupplement, this.container.data(), true, this.baseName).build()}
        </div>
      `
    }
    let header = `
      <div class='basket-form-slide'
        id="mix-attribute-slide--${uuid}"
        data-slide-name="${mixAttribute.groupAttributeName}"
        data-slide-type='mixAttribute'
        data-controller='mixable'
        data-container-id="${this.container.get(0).id}"
        data-mixable="${JSON.stringify(mixAttribute)}"
        data-unit="${this.unitName}"
        data-next="true"
        data-uuid="${uuid}"
    `
    if(this.productId) {
      header += `data-product-id="${this.productId}"`
    }
    header += '>'
    let title = `Choix de ${mixAttribute.groupAttributeName}`
    if(this.container.hasClass('dishset-selection-container')) {
      title += ` pour ${this.container.data().name}`
    }
  
    this.destination.append(`
      ${header}
        <p class='basket-form-slide__title basket-form-slide__maintitle'>${title}</p>
        <p class='basket-form-mixable-price-list basket-form-slide__subtitle basket-form-slide__mainsubtitle'>${this.generateMixAttributeRules(mixAttribute)}</p>
        <div class="basket-form-checkbox-container">
          ${this.addAttributes(mixAttribute)}
        </div>
        ${supplements}
      </div>
    `)
  }

  generateMixAttributeRules(mixAttribute) {
    let rules = []
    if(mixAttribute.minSelection != 0 && mixAttribute.maxSelection == 0) {
      rules.push(`Vous devez choisir au moins ${mixAttribute.minSelection} ${pluralize(this.unitName, mixAttribute.minSelection)}.`)
    } else if(mixAttribute.minSelection == 0 && mixAttribute.maxSelection != 0) {
      rules.push(`Vous pouvez choisir jusqu'à ${mixAttribute.maxSelection} ${pluralize(this.unitName, mixAttribute.maxSelection)}.`)
    } else if(mixAttribute.minSelection != 0 && mixAttribute.maxSelection != 0 && mixAttribute.minSelection == mixAttribute.maxSelection) {
      rules.push(`Vous devez choisir ${mixAttribute.maxSelection} ${pluralize(this.unitName, mixAttribute.maxSelection)}.`)
    } else if(mixAttribute.minSelection != 0 && mixAttribute.maxSelection != 0 && mixAttribute.minSelection == (mixAttribute.minSelection + 1)) {
      rules.push(`Vous pouvez choisir ${mixAttribute.maxSelection} ou ${mixAttribute.maxSelection} ${pluralize(this.unitName, mixAttribute.maxSelection)}.`)
    } else if(mixAttribute.minSelection != 0 && mixAttribute.maxSelection != 0) {
      rules.push(`Vous pouvez choisir entre ${mixAttribute.minSelection} et ${mixAttribute.maxSelection} ${pluralize(this.unitName, mixAttribute.maxSelection)}.`)
    }
    if(![0, 1].includes(mixAttribute.maxPerAttribute) && mixAttribute.maxSelection != 0 && mixAttribute.maxPerAttribute < mixAttribute.maxSelection) {
      rules.push(`Vous pouvez sélectionner au maximum ${mixAttribute.maxPerAttribute} par attribut.`)
    }
    if(!mixAttribute.enablePricingImpact) {
      let maxSize = mixAttribute.listPrice.length
      if(mixAttribute.maxSelection != 0 && mixAttribute.maxSelection < mixAttribute.listPrice.length) {
        maxSize = mixAttribute.maxSelection
      }

      

      if (this.baseName == 'basket_product') {
        let array_prices = []
        for (let step = 0; step < maxSize; step++) {
          // Runs 5 times, with values of step 0 through 4.
          let str;
          let price = mixAttribute.listPrice[step]
          if(price != 0) {
            if ((step + 1) == mixAttribute.listPrice.length) {
              str = `${step + 1} ${pluralize(this.unitName, step + 1)}+ : ${numberToEuro(price)}` 
            } else {
              str = `${step + 1} ${pluralize(this.unitName, step + 1)} : ${numberToEuro(price)}`
            }   
            array_prices.push(str)       
          }

          
        }
        rules.push(array_prices.join(' | '))        
      }


    }
    return rules.join(' ')
  }

  addAttributes(mixAttribute) {
    let html = ''
    let multiple = mixAttribute.maxPerAttribute > 1 || (mixAttribute.maxPerAttribute == 0 && mixAttribute.maxSelection > 1)
    $.each(mixAttribute.attribut_list, (i, attribute) => {
      let content = ''
      let picture = $('#form_slider').data().attributPictures[attribute.uniqueId]
      let value = 0
      if(this.basketProduct) {
        value = $.grep(this.basketProduct.mixableAttributes, (id) => {return id == attribute.id}).length
      }
      if(picture) { content = `<img src="${picture}" />` }
      content += `<p class='basket-form-checkbox__title'>${attribute.name}</p>`
      if(mixAttribute.enablePricingImpact && attribute.pricingImpact > 0 && this.baseName == 'basket_product') {
        content += `<p class="basket-form-checkbox__subtitle">+ ${numberToEuro(attribute.pricingImpact)}</p>`
      }
      html += `
        ${multiple ? this.addMultipleAttribute(attribute, content, value, picture) : this.addSingleAttribute(attribute, content, value, picture)}         
      `
    })
    return html
  }

  addSingleAttribute(attribute, content, value, picture) {
    let css_class = `show-checkbox ${value == 1 ? "has-checked-input" : ""}`
    if(picture) {
      css_class += ` borne-display-mode--${$('#form_slider').data().displayMode}`
    }
    if(value == 1) {
      css_class += ' has-checked-input'
    }
    return `
      <div class="basket-form-checkbox-col">
        <label for="basket-form-mixable-checkbox-${attribute.id}" class='${css_class}'>
          <input type="checkbox" value="${attribute.id}" 
            name="temp_mixables[]"
            id="basket-form-mixable-checkbox-${attribute.id}"
            ${value == 1 ? 'checked' : ''}
          >  
          <input
            type="number"
            disabled
            data-name="${attribute.name}"
            id="basket_product_mix_quantity_${attribute.id}"
            data-mixable='${attribute.id}'
            data-pricing-impact="${attribute.pricingImpact}"
            value='${value}'
            class='mixable_input'
          />
          <div class="${picture ? 'has-picture' : ''}">
            ${content}
          </div>         

        </label>
      </div>
    `
  }

  addMultipleAttribute(attribute, content, value, picture) {
    return `
      <div class="basket-form-checkbox-col borne-display-mode--${$('#form_slider').data().displayMode}">
        <div>
          <div class="${picture ? 'has-picture' : ''}">
            ${content}
            <div class="basket-form-quantity-buttons">
              <div data-action="click->mixable#decrease" class='basket-form-quantity-buttons__decrease'>
                <div>
                  ${svgQuantityMinus()}
                </div>
              </div>
              <input
                type="number"
                disabled
                data-name="${attribute.name}"
                id="basket_product_mix_quantity_${attribute.id}"
                data-mixable='${attribute.id}'
                data-pricing-impact="${attribute.pricingImpact}"
                value='${value}'
                class='mixable_input'
              />
              <div data-action="click->mixable#increase" class='basket-form-quantity-buttons__increase'>
                <div>
                  ${svgQuantityPlus()}
                </div>
              </div> 
            </div>             
          </div>
        </div>
      </div>
    `
  }
}