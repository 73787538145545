export class BasketProductQuantityPrice {
  basePrice() {
    if($('#basket_product_productPriceUnitId :checked').length == 0) {
      return 0
    }
    let productVariationPrice = this.productVariationHandler.selectVariation()
    if(productVariationPrice) {
      return productVariationPrice
    } else {
      return parseFloat($('#basket_product_productPriceUnitId :checked').data().price)
    }
  }

  quantityIncreased() {
    this.quantityInput.val(parseFloat(this.quantityInput.val()) + 1)
    this.quantityChanged()
  }

  quantityDecreased() {
    if(parseFloat(this.quantityInput.val()) == 1) {
      return false 
    }
    this.quantityInput.val(parseFloat(this.quantityInput.val()) - 1)  
    this.quantityChanged()
  }

  quantityChanged() {
    if(isNaN(parseInt(this.quantityInput.val()))) {
      this.quantityInput.val(1)
    }
    $('#basket_product_footer_quantity').val(this.quantityInput.val())
    $(this.element).find('#basket-form-slide__quantity .basket-form-quantity-buttons__decrease').toggleClass(
      'hidden',
      parseFloat(this.quantityInput.val()) == 1
    )
    this.selectSlide()
  }


  calcTotal() {
    let total = this.basePrice()
    if($('.mix_attributes_container .basket-form-slide').length) {
      $.each($('.mix_attributes_container .basket-form-slide'), (i, mixable_slide) => {
        if(mixable_slide.controller) {
          total += mixable_slide.controller.getTotal()
        } else {
          setTimeout(() => {
            total += mixable_slide.controller.getTotal()
          }, 500)
        }
        
      })
    } else {
      total = total * parseFloat($('#basket_product_quantity').val())
    }
    $.each($('.basket-form-supplement-selections .basket-form-quantity-buttons input'), (i, input) => {
      total += parseInt($(input).val()) * parseFloat($(input).data().price)
    })

    $.each($('#basket-form-slide__seasoning :checked'), (i, input) => {
      total += parseFloat($(input).data().price)
    })
    return total
  }
}