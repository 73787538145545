import { MainController } from './main_controller';
import { flashMessage } from '../shared/notify';

import PerfectScrollbar from 'perfect-scrollbar';
export default class CartsuccessController extends MainController {
  printDuplicate() {
    let data = window.ticketData
    $('#bornes-modal-success__bottom__duplicata').remove()
    let json = {
      shopId: data.shopId,
      id: data.uuid,
      dailyNumberOrder: data.number,
      basketPrice: data.price,
      products: data.items
    }
    try {
      app.printTicket(JSON.stringify(json));
    } catch (e) {
      console.log(e)
      flashMessage('', `L'appel JS n'a pas fonctionné : ${e}`, 'success')
    }
  }
}