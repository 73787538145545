import { Controller } from 'stimulus';
import { numberToEuro } from '../shared/number_to_euro';
export default class MixableController extends Controller {

  connect() {
    this.element['controller'] = this
    this.total_price = 0
    this.conf = $(`#${$(this.element).data().containerId}`).get(0).controller.getMixableAttribute($(this.element).data().uuid)
    if(this.conf.maxSelection != 0) { this.max = this.conf.maxSelection}
    this.amount = 0
    $(this.element).find('input').on('click', (e) => { e.stopPropagation() })
    this.set_total()
    //this.set_total()    
  }

  reload(basePrice) {
    this.conf.listPrice = this.conf.listPrice.map((value, index) => {
      if((index + 1) == this.conf.listPrice.length) {
        return parseFloat(value)
      } else {
        return parseFloat(value) + basePrice
      }
    })

    
    this.set_total()
    $(this.element).find('.basket-form-mixable-price-list').text(array_string.join(' | '))
  }

  getTotal() {
    return this.total
  }

  getValid() {
    return this.valid
  }

  set_total() {
    let attrs = []
    let pricingImpactTotal = 0
    $.each($(this.element).find('.mixable_input'), (index, input) => {
      for (let i = 0; i < parseInt($(input).val()); i++) {
        attrs.push($(input).data().mixable)
        pricingImpactTotal += $(input).data().pricingImpact
      }
    })
    this.amount = attrs.length
    $(`#${$(this.element).data().containerId}`).get(0).controller.reloadMixables()
    this.total = 0
    if(this.conf.enablePricingImpact) {
      this.total = pricingImpactTotal
    } else {
      if(this.amount > (this.conf.listPrice.length - 1)) {
        this.total = this.conf.listPrice[this.conf.listPrice.length - 2] + (this.amount - this.conf.listPrice.length + 1) * this.conf.listPrice[this.conf.listPrice.length - 1]
      } else if (this.amount > 0) {
        this.total = this.conf.listPrice[this.amount - 1]
      }  
    }
    
    this.check_amount(this.amount)
    $(`#${$(this.element).data().containerId}`).get(0).controller.reloadNav()
    $(`#${$(this.element).data().containerId}`).get(0).controller.selectSlide()
  }

  toggleCheckbox(element) {
    if(element.prop('checked')) {
      element.prop('checked', false)
      element.next().val(0)
      $(`#${$(this.element).data().containerId}`).get(0).controller.reloadMixables()
    } else if(this.conf.maxSelection == 0 || (element.parents('.basket-form-checkbox-container').find('input:checked').length < this.conf.maxSelection)) {
      element.prop('checked', true)
      element.next().val(1)
      $(`#${$(this.element).data().containerId}`).get(0).controller.reloadMixables()
    } else if(this.conf.maxSelection != 0 && this.amount == this.conf.maxSelection && !element.prop('checked')) {
      console.log('gohugf')
      console.log(element)
      element.parents('.basket-form-checkbox-container').find('.has-checked-input').removeClass('has-checked-input')
      element.parents('.basket-form-checkbox-container').find(':checked').prop('checked', false)
      element.prop('checked', true)
    }
    element.parent().toggleClass('has-checked-input', element.prop('checked'))
    this.set_total()
    this.checkSupplements()

  }

  check_amount(amount) {
    let correct = true;
    if((amount < this.conf.minSelection) || (this.max && amount > this.max)) {
      correct = false
    }
    this.valid = correct
    $(this.element).data('valid', correct)
  }


  increase(e) {
    let input = $(e.currentTarget).parents('.basket-form-quantity-buttons').find('input')
    let valid = true
    if(this.max && this.amount == this.max) {
      valid = false
    }

    if(this.conf.maxPerAttribute != 0 && parseInt(input.val()) == this.conf.maxPerAttribute) {
      valid = false
    }
    if(valid) {
      input.val(parseFloat(input.val()) + 1)
      this.checkQuantity(e)
    }
    this.checkSupplements()
  }

  checkSupplements() {
    if(this.conf.maxSelection != 0 && this.amount == this.conf.maxSelection && $(this.element).find('.basket-form-supplement-selections').length > 0) {
      $('.form-slider').animate({
        scrollTop: $('.form-slider').scrollTop() - $('.form-slider').offset().top + $(this.element).find('.basket-form-supplement-selections').eq(0).offset().top
      })
    }
  }

  decrease(e) {
    let input = $(e.currentTarget).parents('.basket-form-quantity-buttons').find('input')
    if(parseInt(input.val()) == 0) {
      return
    }
    input.val(parseFloat(input.val()) - 1)
    this.checkQuantity(e)
  }

  checkQuantities() {
    $.each($(this.element).find('.basket-form-checkbox-col'), (i, element) => {
      let input = $(element).find('input')
      let valid = true
      if(this.max && this.amount == this.max) {
        valid = false
      }

      if(this.conf.maxPerAttribute != 0 && parseInt(input.val()) == this.conf.maxPerAttribute) {
        valid = false
      }
      $(element).find('.basket-form-quantity-buttons__decrease').toggleClass('hidden', parseInt($(element).find('input').val()) == 0)
      $(element).find('.basket-form-quantity-buttons__increase').toggleClass('hidden', !valid)
    })
  }  

  checkQuantity(e) {
    this.set_total()
    this.checkQuantities()
  }

  change(e) {
    this.set_total()
  }
}
