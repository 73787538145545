import { MainController } from './main_controller';

import { SupplementSelectionHandler } from '../classes/supplement_selection_handler';
import { GenericNav } from '../classes/generic_nav';
import { BasketMenuNav } from '../classes/basket_menu_nav';

import { numberToEuro } from '../shared/number_to_euro';
import { classAgregator } from '../shared/class_agregator';

export default class BasketmenuController extends classAgregator(MainController, GenericNav, BasketMenuNav) {
  static targets = ['slide']

  connect() {
    this.element['controller'] = this
    this.currentSlide = 0
    this.maxSlide = 0
    this.supplementSelectionHandler = new SupplementSelectionHandler
    $.each($('.choose_dish'), (i, dish) => {
      if($(dish).find(':checked').length) {
        $(dish).data('valid', true)
        this.updateDishSetSelection($(dish), false)  
      }
    })
    setTimeout(() => {
      this.reloadNav()
      this.selectSlide()
    }, 200)  
  }

  showRemovable() {
    let removable_id = $('.basket-form-slide.active').data().removable
    $('#basket_item_footer__removable').hide()
    $('.basket-form-slide.active').removeClass('active')
    $(`#${removable_id}`).addClass('basket-form-slide active')
    $(`#${removable_id}`).parent().show()
    $('#basket-form-nav').html('')
    $('#basket_item_footer').html(`
      <div id="basket_item_footer__main">
        <p class='basket_item_footer__return-button' data-action='click->basketmenufooter#prevSlide'>Retour</p>
      </div>
    `)
    $('#basket_item_footer').addClass('return-only') 
  }

  selectDish(e) {
    if($(e.currentTarget).parent().hasClass('disabled_dish')) {
      $(e.currentTarget).prop('checked', false)
    } else {
      $('.loading_app_container').removeClass('d-none')
      this.updateDishSetSelection($(e.currentTarget).parents('.choose_dish').eq(0), true)
      $('.basket-form-slide.active').data('valid', true)  
    }
    
    
  }

  showItemDescription(e) {
    e.preventDefault()
    $('#basket-form__product-description').show()
    $('.basket-form__product-content').html($(e.currentTarget).prev().data().description)
    $('#modal_form').addClass('sub-modal-opened')
  }

  updateDishSetSelection(choose_dish, clicked) {
    if(choose_dish.find(':checked').data().uniqueId == "00000000-0000-0000-0000-000000000000" && clicked) {
      $(`.basket_menu_form #dish_selection_container_${choose_dish.data().id}`).html('')
      $('.basket-form-slide').eq(this.currentSlide).data('valid', true)
      this.tryNextSlide()
      $('.loading_app_container').addClass('d-none')
    } else if(choose_dish.find(':checked').data().uniqueId != "00000000-0000-0000-0000-000000000000") {
      let params = {
        id: $('#form_slider').data().id,
        dish_id: choose_dish.data().id,
        dish_set_selection_id: choose_dish.data().dishSelectionId,
        product_unique_id: choose_dish.find(':checked').data().uniqueId,
        dish_set_id: choose_dish.find(':checked').data().dishSetId,
        clicked: clicked
      }
      $.get(`/basket_menus/add_products?${new URLSearchParams(params).toString()}`).done(() => {
        $('.loading_app_container').addClass('d-none')
      })      
    }
    
  }

  attributeSelected() {
    $('.basket-form-slide.active').data('valid', 'true')
    this.tryNextSlide()
  }  

  supplementChanged(e) {
    this.supplementSelectionHandler.quantityChanged($(e.currentTarget), $(e.currentTarget).data().factor == 'minus' ? -1 : 1)
    this.setFooter()
  } 

  calcTotal() {
    let total = parseFloat($(this.element).data().price)
    $.each($('.choose_dish :checked'), (i, dish) => {
      total += parseFloat($(dish).data().pricingImpact)
    })
    $.each($('.basket-form-supplement-selections .basket-form-quantity-buttons input'), (i, input) => {
      total += parseInt($(input).val()) * parseFloat($(input).data().price)
    })    
    $.each($('.choose_dish basket-form-slide :selected'), (i, option) => {
      total += parseFloat($(option).data().pricingImpact)
    })
    return total
  }

  submit() {
    let type = $('#form_slider').hasClass('new_basket_menu') ? 'POST' : 'PUT'
    $('.loading_app_container').removeClass('d-none')
    $.ajax({
      type: type,
      url: $('#form_slider').attr('action'),
      data: $('#form_slider').serialize(),
      success: (data) => {
        $('.loading_app_container').addClass('d-none')
        if(data.code == 200) {
          this.getControllerByIdentifier('cart').refresh()
          if(data.suggestions) {
            $.get(`/shops/suggestions?type=SmartCollectMenu&id=${data.smartCollectMenuId}`)
          } else {
            $('.modal').modal('hide')  
          }
          
          
        }
      }
    })
  }
}
