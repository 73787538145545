export function productHeader(product) {
  $('.loading_app_container').removeClass('d-none')
  let footer_controller = product.data().type == 'basket_menus' ? 'menu' : 'product'
  let rectangle_picture = $('#shop-show').data().display == 'square' ? '' : `<img src='${product.find('.shop_show_product_picture').attr('src')}' id="basket_item_modal_rectangle_picture" />`
  $('#modal_form').modal('show')
  $('#modal_form .modal-content').html(`
    <div class='basket_item_modal basket_item_modal_display_${$('#shop-show').data().display}' data-controller='basketproductheader'>
      ${rectangle_picture}
      <div type="button" id="close_modal_button" data-dismiss="modal">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="material-symbols:close-rounded">
        <path id="Vector" d="M16 17.7034L10.038 23.6654C9.81496 23.8885 9.53106 24 9.18632 24C8.84157 24 8.55767 23.8885 8.3346 23.6654C8.11153 23.4423 8 23.1584 8 22.8137C8 22.4689 8.11153 22.185 8.3346 21.962L14.2966 16L8.3346 10.038C8.11153 9.81491 8 9.53101 8 9.18627C8 8.84153 8.11153 8.55762 8.3346 8.33455C8.55767 8.11148 8.84157 7.99995 9.18632 7.99995C9.53106 7.99995 9.81496 8.11148 10.038 8.33455L16 14.2965L21.962 8.33455C22.1851 8.11148 22.469 7.99995 22.8137 7.99995C23.1585 7.99995 23.4424 8.11148 23.6654 8.33455C23.8885 8.55762 24 8.84153 24 9.18627C24 9.53101 23.8885 9.81491 23.6654 10.038L17.7035 16L23.6654 21.962C23.8885 22.185 24 22.4689 24 22.8137C24 23.1584 23.8885 23.4423 23.6654 23.6654C23.4424 23.8885 23.1585 24 22.8137 24C22.469 24 22.1851 23.8885 21.962 23.6654L16 17.7034Z" fill="#1B1F27"/>
        </g>
        </svg>

      </div>      
      <div class='basket_item_modal_header'>

        <div class="basket_item_head">
          <img src='${product.find('.shop_show_product_picture').attr('src')}' style='object-fit: ${product.find('.shop_show_product_picture').css('objectFit')}' />
        </div>
        <div class="basket_item_informations_container">
          <div>
            <p class='basket_item_title'>${product.find('.item_name').text()}</p>
            <p class='basket_item_price'>${product.find('.item_price').html()}</p>
          </div>
          
          <p class='basket_product_modal_description'></p>
          <p class='basket_product_modal_description_show_more' style='display: none;' data-action="click->basketproductheader#showMore">Plus d’infos<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49998 3.33331V12.6666M3.83331 7.99998H13.1666" stroke="#6B7281" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg> </p>
        </div>
      </div>

      <div id="basket-form-nav"></div>
      <div id="basket-form-nav__titles"></div>

      <div class="form-slider position-relative">

      </div>

      <div id="basket_item_footer" data-controller='basket${footer_controller}footer'></div>
    </div>
  `)
}
