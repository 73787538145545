import { MainController } from './main_controller';

export default class ShopsplashdistributiontypeController extends MainController {
  selectDistributionType(e) {
    console.log($(e.currentTarget).data().distributionType)
    $.ajax({
      method: 'POST',
      url: '/shops/upsert_distribution_type',
      data: {distribution_type: $(e.currentTarget).data().distributionType}
    }).done(() => {
      Turbolinks.visit('/')
    })
  }
}