import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import * as sourceStackTrace from 'sourcemapped-stacktrace';
const application = Application.start()
window.onerror = function(msg, url, line, col, error) {
   // Note that col & error are new to the HTML 5 spec and may not be 
   // supported in every browser.  It worked for me in Chrome.
  $('.loading_app_container').addClass('d-none')
  console.log(error)
  let method_name
  let controller_name
  let traces = []
  sourceStackTrace.mapStackTrace(error.stack.split('\n')[0], (str) => {
    $.each(str, (i, e) => {
      let sub_str = e.trim().replace('at ', '').replace('(webpack:///', '').replace(')', '')
      if(e.includes('app/javascript')) {
        traces.push(sub_str)
        if(i == 0) {
          method_name = sub_str.split(' ')[0]
          controller_name = sub_str.split(' ')[1].split('/').reverse()[0].split('.')[0]
        }
      }
    })
    console.log(traces)
    $.ajax({
      method: 'POST',
      url: '/log_js_error',
      data: {
        msg: msg,
        traces: traces,
        method_name: method_name,
        controller_name: controller_name,
        env: "Borne"
      }
    })
  }) 

  var suppressErrorAlert = true;
  // If you return true, then error alerts (like in older versions of 
  // Internet Explorer) will be suppressed.
  return suppressErrorAlert;
};
const context = require.context(".", true, /\.js$/)
application.load(definitionsFromContext(context))
const defaultErrorHandler = application.handleError;
const customErrorHandler = (err) => {
  defaultErrorHandler(err)
  let method_name
  let controller_name  
  let traces = []
  sourceStackTrace.mapStackTrace(err.stack, (str) => {
    $.each(str, (i, e) => {
      let sub_str = e.trim().replace('at ', '').replace('(webpack:///', '').replace(')', '')
      if(e.includes('app/javascript')) {
        
        if(i == 0) {
          traces.push(sub_str)
          method_name = sub_str.split(' ')[0]
          controller_name = sub_str.split(' ')[1].split('/').reverse()[0].split('.')[0]
        }
      }
    })
    $.ajax({
      method: 'POST',
      url: '/log_js_error',
      data: {
        msg: err.message,
        traces: traces,
        method_name: method_name,
        controller_name: controller_name,
        env: "Borne"
      }
    })
  }) 
}

application.handleError = customErrorHandler