import { MainController } from './main_controller';

import { MixAttributeHandler } from '../classes/mix_attribute_handler';
import { SupplementSelectionBuilder } from '../classes/supplement_selection_builder';
import { DishSetSelectionVariationHandler } from '../classes/dish_set_selection_variation_handler';

import { numberToEuro } from '../shared/number_to_euro';
import { classAgregator } from '../shared/class_agregator';

export default class DishsetselectionController extends classAgregator(MainController, DishSetSelectionVariationHandler) {
  connect() {
    this.element['controller'] = this
    this.existingProduct = $(this.element).parent().data().editProduct
    this.productVariationInput = $(`#product_variation_id_${$(this.element).data().dishSetSelectionId}_${$(this.element).data().productSelectionId}`)
    this.mixableAttributesInput = $(`#mixableAttributes_${$(this.element).data().dishSetSelectionId}_${$(this.element).data().productSelectionId}`)
    this.groupSupplements = $(this.element).data().groupSupplement.sort((a, b) => {
      let a_order = $(this.element).data().localGroupSupplements[a.id] ? parseInt($(this.element).data().localGroupSupplements[a.id].order) : 1000
      let b_order = $(this.element).data().localGroupSupplements[b.id] ? parseInt($(this.element).data().localGroupSupplements[b.id].order) : 1000

      return a_order - b_order
    })
    if(this.existingProduct && this.existingProduct.productId != $(this.element).data().productId) {
      this.existingProduct = undefined
    }
    if(this.existingProduct) {
      this.productVariationInput.val(this.existingProduct.productVariationId)
    }
    this.addedGroupSupplements = []
    this.groupAttributeOrders = $(this.element).data().groupAttributeOrders
    this.productSelectionId = $(this.element).data().productSelectionId
    this.baseName = `basket_menu[dish_set_selections_attributes][${$(this.element).data().dishSetSelectionId}][product_selections_attributes][${this.productSelectionId}]`
    this.handleVariations()
    if($(this.element).data().mixables.length > 0) { this.handleMixables() }
    if($(this.element).data().seasoning.length > 0 ) { this.addSeasoning() }
    this.addSupplement()
    if($(this.element).find('div').length == 0) {
      $(this.element).hide()
    }    
    if($(this.element).data().removable.length) {
      this.addRemovable()
      let data = `basket-form-slide__removable-ingredient_${this.productSelectionId}`
      if($(this.element).find('.basket-form-slide').length) {
        $(this.element).find('.basket-form-slide').eq(-1).data('removable', data)
      } else {
        $(`#basket-form-slide__dish-${$(this.element).data().dishId}`).data('removable', data)
      }
    }
    if($(this.element).data().clicked) { $('#form_slider').get(0).controller.tryNextSlide() }
    $('#form_slider').get(0).controller.reloadNav()
    $('#form_slider').get(0).controller.selectSlide()
  }                     

  addSupplement() {
    let groupSupplements = this.groupSupplements.filter((group) => {return !this.addedGroupSupplements.includes(group.id)})
    if(groupSupplements.length) {
      $(this.element).append(`
        <div class="basket-form-supplement-selections basket-form-slide"
          data-slide-name="Suppléments"
          data-slide-type='supplements'
          data-valid='true'
          data-product-id="${$(this.element).data().productId}"
          data-next='true'>
        </div>
      `)
      $.each(groupSupplements, (i, groupSupplement) => {
        $(this.element).find('> .basket-form-supplement-selections').append(
          new SupplementSelectionBuilder(groupSupplement, $(this.element).data(), false, this.baseName).build()
        )
      })
    }        
  }

  addRemovable() {
    let html = `
      <div data-slide-type='removable_ingredient' id="basket-form-slide__removable-ingredient_${this.productSelectionId}" class='basket-form-slide__removable-ingredient'>
        <p class='basket-form-slide__title'>Personnalisation de ${$(this.element).data().name}</p>
        <p class='basket-form-slide__subtitle'>
          Sélectionnez les ingrédients que vous souhaitez enlever.
        </p>
        <div class="basket-form-checkbox-container">
    `

    $.each($(this.element).data().removable, (i, e) => {
      let checked = $(this.element).data().attributes.removableIngredients.includes(e.id)
      let content = ''
      let picture = $('#form_slider').data().removableIngredientPictures[e.uniqueId]
      if(picture) {
        content += `<img src="${picture}" />`
      }      
      content += `<p class='basket-form-checkbox__title'>${e.name}</p>`      
      html += `
        <div class="basket-form-checkbox-col">
          <label for="basket_product_removableIngredient_${this.baseName}_${e.id}" class='show-checkbox ${checked ? 'has-checked-input' : ''} borne-display-mode--${$('#form_slider').data().displayMode}'>
            <input type="checkbox" value="${e.id}" 
              ${checked ? 'checked' : ''}
              name="${this.baseName}[removableIngredients][]"
              id="basket_product_removableIngredient_${this.baseName}_${e.id}"
            >   
            <div>
              ${content}
            </div>         

          </label>
        </div>
      `
    })

    html += "</div></div>"
    $(this.element).append(html)
  }

  addSeasoning() {
    let html = `
      <div data-slide-type='seasoning' class='basket-form-slide' data-product-id="${this.productSelectionId}" data-valid="true" data-next="true">
        <p class='basket-form-slide__title'>Assaisonnement de ${$(this.element).data().name}</p>
        <div class="basket-form-checkbox-container">
    `

    $.each($(this.element).data().seasoning, (i, e) => {
      let checked = $(this.element).data().attributes.seasonings.includes(e.id)
      let content = ''
      let picture = $('#form_slider').data().seasoningPictures[e.uniqueId]
      if(picture) {
        content += `<img src="${picture}" />`
      }      
      content += `<p class='basket-form-checkbox__title'>${e.name}</p>`

      html += `
        <div class="basket-form-checkbox-col">
          <label for="basket_product_seasonings_${this.baseName}_${e.id}" class='show-checkbox ${checked ? 'has-checked-input' : ''} borne-display-mode--${$('#form_slider').data().displayMode}'>
            <input type="checkbox" value="${e.id}" 
              ${checked ? 'checked' : ''}
              name="${this.baseName}[seasonings][]"
              id="basket_product_seasonings_${this.baseName}_${e.id}"
            >   
            <div>
              ${content}
            </div>         
          </label>
        </div>
      `
    })

    html += "</div></div>"
    $(this.element).append(html)
  }

  getMixableAttribute(uuid) {
    return this.mixAttributeHandler.getMixableAttribute(uuid)
  }  

  handleMixables() {
    this.mixAttributeHandler = new MixAttributeHandler
    let result = this.mixAttributeHandler.populate(
      $(this.element).data().mixables,
      "article",
      $(this.element),
      $(this.element),
      this.groupSupplements,
      this.productSelectionId,
      this.baseName
    )
    this.addedGroupSupplements = result.groupSupplementIds
  }

  reloadMixables() {
    let attrs = []
    $.each($(this.element).find('.mixable_input'), (index, input) => {
      for (let i = 0; i < parseInt($(input).val()); i++) {
        attrs.push($(input).data().mixable)
      }
    })

    this.mixableAttributesInput.val(attrs.join('_'))   
  } 

  reloadNav() {
    $('#form_slider').get(0).controller.reloadNav()
  } 

  selectSlide() {
    $('#form_slider').get(0).controller.selectSlide()
  }
}