import { Controller } from 'stimulus';
window.moment = require('moment');
import 'moment/locale/fr';
export default class DeliverymodalController extends Controller {
  connect() {
    this.date = moment($('.select_delivery_params').data().delivery, "DD/MM/YYYY HH:mm")
    this.date_form = moment($('.select_delivery_params').data().delivery, "DD/MM/YYYY HH:mm")
    $.get('/shops/hours_json', (data) => {
      this.hours = data.hours
      $.each(this.hours['exceptional'], (i, exceptional) => {
        exceptional.begin_date = moment(exceptional.begin_date, 'YYYY-MM-DD')
        if(exceptional.hour_type == 'long_closing') {
          exceptional.end_date = moment(exceptional.end_date, 'YYYY-MM-DD')
        }
      })
      this.populateDate()
      this.populateMinutes()      
    })

  }

  populateDate() {
    let html = ""
    for (let i = 0; i < 31; i++) {
      let option_text = ''
      let current_date = moment().add(i, 'days')
      if(i == 0) {
        option_text = "Aujourd'hui, "
      } else if (i == 1) {
        option_text = 'Demain, '
      }
      option_text += current_date.format('ddd D MMM')
      let custom_day = this.getCustomDay(current_date)  

      let current_day = custom_day ? custom_day : this.hours[current_date.format('d')][0]
      html += `
        <option ${!current_day.opened ? 'disabled' : ''} value="${current_date.format('DD/MM/YYYY')}" ${this.date_form.format('DD/MM/YYYY') == current_date.format('DD/MM/YYYY') ? 'selected' : ''}>${option_text}</option>"
      `
    }
    $(this.element).find('.delivery_date_select').html(html)
  }

  dateChanged() {
    this.date_form = moment(`${$(this.element).find('.delivery_date_select').val()} ${$(this.element).find('.delivery_minute_select').val()}`, "DD/MM/YYYY HH:mm")
    this.populateMinutes()
  }

  getCustomDay(moment_date) {
    let custom_day = undefined
    $.each(this.hours['exceptional'], (i, exceptional) => {
      if(exceptional.hour_type == 'long_closing' && moment_date.isSameOrAfter(exceptional.begin_date) && moment_date.isSameOrBefore(exceptional.end_date)) {
        custom_day = exceptional
      } else if(exceptional.begin_date.format('YYYY-MM-DD') == moment_date.format('YYYY-MM-DD')) {
        custom_day = exceptional
      }
    })
    return custom_day
  }

  populateMinutes() {
    let html = ''
    let minutes = 0
    let begin_minutes = [0, 30].includes(this.date_form.minutes()) ? 0 : 15
    begin_minutes = 0
    if(this.date_form.format('YYYY MM DD') == moment().format('YYYY MM DD')) {
      minutes = moment().minutes() + 46 + moment().hours() * 60
    }

    let custom_day = this.getCustomDay(this.date_form)
    let current_day = custom_day ? custom_day : this.hours[this.date_form.format('d')][0]
    let open_hour = moment(`${this.date_form.format('YYYY MM DD')} ${current_day.hour_min}:${current_day.minute_min}`, 'YYYY MM DD hh:mm')
    let end_hour = moment(`${this.date_form.format('YYYY MM DD')} ${current_day.hour_max}:${current_day.minute_max}`, 'YYYY MM DD hh:mm')
    let closing_min
    let closing_max
    if(current_day.closing_hours) {
      closing_min = moment(`${this.date_form.format('YYYY MM DD')} ${current_day.close_hour_min}:${current_day.close_minute_min}`, 'YYYY MM DD hh:mm')
      closing_max = moment(`${this.date_form.format('YYYY MM DD')} ${current_day.close_hour_max}:${current_day.close_minute_max}`, 'YYYY MM DD hh:mm')
    }

    for (let i = begin_minutes; i < 1410; i += parseInt($(this.element).data().slot)) {
      if(i >= minutes) {
        let disabled = false
        let starting_minute = this.date_form.startOf('day').add(i, 'minutes')
        let formatted_starting_minute = starting_minute.format('HH:mm')
        let ending_minute = this.date_form.startOf('day').add(i + parseInt($(this.element).data().slot), 'minutes')
        let formatted_ending_minute = ending_minute.format('HH:mm')

        if(starting_minute.isSameOrBefore(open_hour) || ending_minute.isSameOrAfter(end_hour)) {
          disabled = true
        }
        
        if(!disabled && closing_min) {        
          if(
            closing_min.isBetween(starting_minute, ending_minute, undefined, '[]') ||
            closing_max.isBetween(starting_minute, ending_minute, undefined, '[]') ||
            (starting_minute.isSameOrAfter(closing_min) && ending_minute.isSameOrBefore(closing_max))
          )
          disabled = true
        }

        html += `
          <option ${disabled ? 'disabled' : ''} ${this.date_form.format('HH:mm') == starting_minute ? 'selected' : ''} value="${formatted_starting_minute}">${formatted_starting_minute} - ${formatted_ending_minute}</option>
        `
      }

    }
    $(this.element).find('.delivery_minute_select').html(html)
  }

  closeModal() {
    $('#modal_form').modal('hide')
  }

  validDate() {
    let text;
    let date = moment(`${$(this.element).find('.delivery_date_select').val()} ${$(this.element).find('.delivery_minute_select').val()}`, "DD/MM/YYYY HH:mm")
    if(date.format('YYYY MM DD') == moment().format('YYYY MM DD')) {
      text = "Aujourd'hui"
    } else if(date.format('YYYY MM DD') == moment().add(1, 'days').format('YYYY MM DD')) {
      text = "Demain"
    } else {
      text = `Le ${date.format('DD/MM/YYYY')}`
    }
    text += ` entre ${date.format('HH:mm')} et ${date.clone().add(30, 'minutes').format('HH:mm')}`
    $('.display_delivery_at').text(text)
    $('.select_delivery_params').data('delivery', date.format('DD/MM/YYYY HH:mm'))
    $.ajax({
      method: 'POST',
      url: '/change_delivery_date',
      data: {delivery_at: date.format()}
    }).done(() => {
      $('#modal-cart-container').get(0).cartcontent.refresh()
      $('#modal_form').modal('hide')

    })

  }
}
