import { numberToEuro } from '../shared/number_to_euro';
import { svgPlusCart, svgArrowRight } from '../shared/svg_icons';
import { GenericNav } from './generic_nav';

export class BasketMenuNav extends GenericNav {
  reloadNav() {
    let index = 0
    let buttons = ""
    let products = {}
    $.each($('.basket-form-slide'), (i, slide) => {
      let button_name
      if($(slide).data().productId) {
        if(products[$(slide).data().productId]) {
          products[$(slide).data().productId] = products[$(slide).data().productId] + 1
        } else {
          products[$(slide).data().productId] = 1
        }
        button_name = `${index}.${String.fromCharCode(96 + products[$(slide).data().productId])}`
        buttons += this.addSlideButton(button_name, $(slide).data().slideName, true)
      } else {
        index += 1
        button_name = index
        buttons += this.addSlideButton(button_name, $(slide).data().slideName, false)
      }
      
    })

    $('#basket-form-nav').html(buttons)
    $('#basket-form-nav__titles').html('')
    if($('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__maintitle').length == 1) {
      $('#basket-form-nav__titles').append(`
        <p class='basket-form-slide__title'>${$('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__maintitle').eq(0).text()}</p>
      `)
      if($('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__mainsubtitle').length == 1) {
        $('#basket-form-nav__titles').append(`
          <p class='basket-form-slide__subtitle'>${$('.basket-form-slide').eq(this.currentSlide).find('.basket-form-slide__mainsubtitle').eq(0).text()}</p>
        `)
      }
    }    
    $('.basket-form-nav__button').removeClass('active')
    $('.basket-form-nav__button').eq(this.currentSlide).addClass('active')
    if(!$('.basket-form-nav__button.active').is(':visible')) {
      let current_active = $('.basket-form-nav__button.active')
      current_active.removeClass('active')
      current_active.prevAll(':visible').eq(0).addClass('active')
    }
    if($('.basket-form-slide').eq(this.currentSlide).hasClass('choose_dish') && $('.basket-form-slide').eq(this.currentSlide).data().dishGroupId.length) {
      this.checkDishGroup()
    }
    $.each($('.basket-form-nav__button'), (i, e) => {
      $(e).toggleClass('forbidden', i > this.maxSlide)
    })

    $('#basket-form-nav').animate({scrollLeft: $('.basket-form-nav__button.active').offset().left})
  }

  prevSlide() {
    
    if($('.basket-form-slide.active').hasClass('basket-form-slide__removable-ingredient')) {
      let element =  $('.basket-form-slide.active')
      //element.parent().hide()
      element.parents('.choose_dish').eq(0).addClass('active')
      element.removeClass('basket-form-slide active')
      this.reloadNav()
      this.selectSlide()
    } else if (this.currentSlide == 0) {
      $('#modal_form').modal('hide')
    } else {
      this.currentSlide -= 1
      this.reloadNav()
      this.selectSlide()
    }
  }

  addSlideButton(index, name, hidden) {
    return `
      <div class='basket-form-nav__button ${hidden ? '' : 'basket-form-nav__button--visible'}' data-action="click->basketproductheader#clickNav" style='display: ${hidden ? 'none' : 'flex'}'>
        <div class='basket-form-nav__button__square'>${index}</div>
        <div class='basket-form-nav__button__text'>${name}</div>
      </div>
    `
  }

  setFooter() {
    $('.basket_item_price').text(numberToEuro(this.calcTotal()))
    let valid = $('.basket-form-slide').eq(this.currentSlide).data().valid && ([true, 'true'].includes($('.basket-form-slide').eq(this.currentSlide).data().valid))
    let html = ''
    if($('.basket-form-slide.active').data().removable) {
      html += `
        <div id="basket_item_footer__removable">
          <p data-action="click->basketmenufooter#showRemovable">Personnaliser les ingrédients</p>
        </div>
      `      
    }
    if(this.currentSlide == ($('.basket-form-slide').length - 1) && valid) {
      html += `
        <div id="basket_item_footer__main">
          <p class='basket_item_footer__return-button' data-action='click->basketmenufooter#prevSlide'>Retour</p>
          <div>
            <p class='basket_item_footer_submit-button' data-action='click->basketmenufooter#submit'>
              <span>Ajouter au panier (${numberToEuro(this.calcTotal())})</span>
              ${svgPlusCart()}
            </p>
          </div>
        </div>
      `
      $('#basket_item_footer').html(html)
      $('#basket_item_footer').removeClass('return-only') 
    } else if($('.basket-form-slide').eq(this.currentSlide).data().next && valid) {
      html += `
        <div id="basket_item_footer__main">
          <p class='basket_item_footer__return-button' data-action='click->basketmenufooter#prevSlide'>Retour</p>
          <div>
            <p class='basket_item_footer_submit-button' data-action='click->basketmenufooter#nextSlide'>
              <span>Suivant</span>
              ${svgArrowRight()}
            </p>
          </div>
        </div>
      `
      $('#basket_item_footer').html(html)
      $('#basket_item_footer').removeClass('return-only') 
    } else {
      html += `
        <div id="basket_item_footer__main">
          <p class='basket_item_footer__return-button' data-action='click->basketmenufooter#prevSlide'>Retour</p>
        </div>
      `
      $('#basket_item_footer').html(html)
      $('#basket_item_footer').addClass('return-only')     
    }
  }

  tryNextSlide() {
    let slide = $('.basket-form-slide.active')
    this.currentSlide = slide.index('.basket-form-slide') + 1
    if(this.currentSlide == $('.basket-form-slide').length) {
      this.currentSlide = $('.basket-form-slide').length - 1
    }
    if(this.maxSlide < this.currentSlide) {
      this.maxSlide = this.currentSlide
    }
    this.selectSlide()
    this.reloadNav()
    console.log('currentSlide', this.currentSlide)
    console.log($('.basket-form-slide').eq(this.currentSlide).data().valid)
    this.setFooter()
  }

  checkDishGroup() {
    let currentDishSlide = $('.basket-form-slide').eq(this.currentSlide)
    let dishGroup = $(this.element).data().dishGroups[currentDishSlide.data().dishGroupId]
    let prevDishSlides = currentDishSlide.prevAll('.choose_dish').filter((i, e) => {
      return $(e).data().dishGroupId.length && $(e).data().dishGroupId == currentDishSlide.data().dishGroupId
    })
    let nextDishSlides = currentDishSlide.nextAll('.choose_dish').filter((i, e) => {
      return $(e).data().dishGroupId.length && $(e).data().dishGroupId == currentDishSlide.data().dishGroupId
    })
    let previouslySelectedDishes = 0
    $.each(prevDishSlides, (i, e) => {
      if($(e).find('input:checked').data().uniqueId != "00000000-0000-0000-0000-000000000000") {
        previouslySelectedDishes += 1
      }
    })  

    let can_skip = dishGroup.minSelection == 0 || ((nextDishSlides.length + previouslySelectedDishes) >= dishGroup.minSelection) 
    let can_choose = dishGroup.maxSelection == 0 || (previouslySelectedDishes < dishGroup.maxSelection)

    if(can_skip) {
      currentDishSlide.find('.basket_menu_dish_set_empty').toggleClass("disabled_dish", false)
    } else {
      if(currentDishSlide.find('label.basket_menu_dish_set_empty').find('input').prop('checked')) {
        currentDishSlide.find('label.basket_menu_dish_set_empty').find('input').prop('checked', false)
        currentDishSlide.data('valid', false)
      }    
      currentDishSlide.find('label.basket_menu_dish_set_empty').removeClass('has-checked-input')
      currentDishSlide.find('label.basket_menu_dish_set_empty').find('input').prop('checked', false)
      currentDishSlide.find('label.basket_menu_dish_set_empty').addClass('disabled_dish')  
    }
    
    if(can_choose) {
      currentDishSlide.find('label').not('.basket_menu_dish_set_empty').removeClass('disabled_dish')
    } else {
      if(currentDishSlide.find('label').not('.basket_menu_dish_set_empty').find('input').prop('checked')) {
        currentDishSlide.find('label').not('.basket_menu_dish_set_empty').find('input').prop('checked', false)
        currentDishSlide.data('valid', false)
      }
      currentDishSlide.find('label').not('.basket_menu_dish_set_empty').removeClass('has-checked-input')
      currentDishSlide.find('label').not('.basket_menu_dish_set_empty').find('input').prop('checked', false)
      currentDishSlide.find('label').not('.basket_menu_dish_set_empty').addClass('disabled_dish')  
    }

    if(currentDishSlide.find('input:checked').length == 0) {
      currentDishSlide.data('valid', false)
    }
  }
 
}