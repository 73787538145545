export class GenericNav {
  selectSlide() {
    $('.basket-form-slide.active').removeClass('active')
    $('.basket-form-slide').eq(this.currentSlide).addClass('active')
    this.setFooter()
  }

  nextSlide() {
    this.currentSlide += 1
    this.maxSlide = this.currentSlide
    this.selectSlide()
    this.reloadNav()
  }

  clickNav(e) {
    if(!$(e.currentTarget).hasClass('active') && !$(e.currentTarget).hasClass('forbidden')) {
      this.currentSlide = $(e.currentTarget).index()
      this.maxSlide = this.currentSlide
      this.selectSlide()
      this.reloadNav()
    }
  }   
}